import React from 'react'
import { StaticImage } from "gatsby-plugin-image";

const Hoofdgebouw = () => {
    return (
      <div className="bg-gray-white border-0 font-inter">
        <div className=" flex flex-row justify-center items-center bg-gray-white mb-16  lg:mx-64">
          <div className="relative">
            <StaticImage
              src="https://res.cloudinary.com/yungnuts/image/upload/v1643545534/CVT/WEB3_dtivle.jpg"
              alt="Mooie foto"
              className=" max-h-screen"
            />
            <div className="hidden lg:block absolute right-0 bottom-0 bg-blue-effect  p-8 px-16 text-gray-white">
              <div className="font-semibold ">Kantoor Aalst</div>
              <div>Keppestraat 119-121</div>
              <div>9320 Aalst</div>
            </div>
          </div>
        </div>
        <div className="lg:hidden flex flex-row justify-center ">
          <div className="flex-col justify-center items-center w-64 text-center  bg-blue-effect  p-8 px-16 text-gray-white">
            <div className="font-semibold ">Kantoor Aalst</div>
            <div>Keppestraat 119-121</div>
            <div>9320 Aalst</div>
          </div>
        </div>
      </div>
    );
}

export default Hoofdgebouw
