import React from "react";

const Intro = () => {
  return (
    <div className="bg-gray-white pt-36 xl:pt-52 2xl:pt-48 font-inter">
      <div className="grid grid-cols-12 w-full h-full">
        <div className="col-span-1 md:col-span-2 xl:col-span-3"></div>
        <div className="col-span-10 md:col-span-8 xl:col-span-6  xl:px-16">
          <div className="text-3xl sm:text-5xl font-semibold ">
            Uw recht is onze zorg
          </div>
          <p className="text-lg mt-6">
            
          </p>
        </div>
        <div className="col-span-1 md:col-span-2 xl:col-span-3"></div>
      </div>
    </div>
  );
};

export default Intro;
